import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Page, PageRecord } from '@aeb/models/domain/page';
import { Paginable } from '@aeb/models/support/paginable';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  constructor(
    private http: HttpClient
  ) { }

  find(params?: any): Observable<Paginable<PageRecord>> {
    return this.http.get<Paginable<PageRecord>>(`${environment.apiUrl}pages`, { params });
  }

  findOne(id: string): Observable<PageRecord> {
    return this.http.get<PageRecord>(`${environment.apiUrl}pages/${id}`);
  }

  add(body?: Page): Observable<PageRecord> {
    return this.http.post<PageRecord>(`${environment.apiUrl}pages`, body);
  }

  update(id: number, body?: Page): Observable<PageRecord> {
    return this.http.put<PageRecord>(`${environment.apiUrl}pages/${id}`, body);
  }

  remove(id: number): Observable<PageRecord> {
    return this.http.get<PageRecord>(`${environment.apiUrl}pages/${id}`);
  }
}
