import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Profile, ProfileRecord } from '@aeb/models/domain/profile';
import { ProvisionalProfile } from '../_models/provisional-profile';
import { Paginable } from '@aeb/models/support/paginable';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private http: HttpClient
  ) { }

  getProfiles(params?: any): Observable<Paginable<ProfileRecord>> {
    return this.http.get<Paginable<ProfileRecord>>(`${environment.apiUrl}profiles`, { params });
  }

  saveProfiles(body?: Profile[]): Observable<ProfileRecord[]> {
    return this.http.put<ProfileRecord[]>(`${environment.apiUrl}profiles`, body);
  }

  getProvisionalProfiles(params?: any): Observable<Paginable<ProvisionalProfile>> {
    return this.http.get<Paginable<ProvisionalProfile>>(`${environment.apiUrl}provisional-profiles`, { params });
  }

  saveProvisionalProfiles(id: number, body?: ProvisionalProfile): Observable<ProvisionalProfile> {
    return this.http.put<ProvisionalProfile>(`${environment.apiUrl}provisional-profiles/${id}`, body);
  }
}
