import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Post, PostRecord } from '@aeb/models/domain/post';
import { Paginable } from '@aeb/models/support/paginable';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor(
    private http: HttpClient
  ) { }

  find(params?: any): Observable<Paginable<PostRecord>> {
    return this.http.get<Paginable<PostRecord>>(`${environment.apiUrl}posts`, { params });
  }

  findOne(id: number, params?: any): Observable<PostRecord> {
    return this.http.get<PostRecord>(`${environment.apiUrl}posts/${id}`, { params });
  }

  add(body?: Post): Observable<PostRecord> {
    return this.http.post<PostRecord>(`${environment.apiUrl}posts`, body);
  }

  update(body?: Post): Observable<PostRecord> {
    return this.http.post<PostRecord>(`${environment.apiUrl}posts`, body);
  }

  remove(id: number): Observable<Paginable<PostRecord>> {
    return this.http.delete<Paginable<PostRecord>>(`${environment.apiUrl}posts/${id}`);
  }
}
