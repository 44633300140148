import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Paginable } from '@aeb/models/support/paginable';
import { HttpClient } from '@angular/common/http';
import { Country } from '../_models/country';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(
    private http: HttpClient
  ) { }

  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(`${environment.apiUrl}countries`);
  }
}
