import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from './_services/authentication.service';
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(
    next  : ActivatedRouteSnapshot,
    state : RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    return this.authenticationService.verify()
      .pipe(catchError(this.handleError('auth', false)))
      .pipe(map((approved) => {
        if (!approved) {
          this.router.navigate(['/login']);
        }
        return approved;
      }));
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => of(result as T);
  }
}
