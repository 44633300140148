import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injectable } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule }  from '@angular/material/select';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { AuthGuard } from './auth.guard';
import { AuthenticationService } from './_services/authentication.service';
import { UserService } from './_services/user.service';
import { ProfileService } from './_services/profile.service';
import { GroupService } from './_services/group.service';
import { CountryService } from './_services/country.service';
import { PostService } from './_services/post.service';
import { PostTagService } from './_services/post-tag.service';
import { PublicationService } from './_services/publication.service';
import { ImageService } from './_services/image.service';
import { DownloadService } from './_services/download.service';
import { FilePermissionService } from './_services/file-permission.service';
import { PostTagAssignmentService } from './_services/post-tag-assignment.service';
import { LayoutModule } from '@angular/cdk/layout';

import { MatPaginatorIntl } from '@angular/material/paginator';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './token.interceptor';

import { UploadManagerComponent } from './home/upload-manager.component';
import { UploadService } from './_services/upload.service';
import { FileService } from './_services/file.service';
import { PageService } from './_services/page.service';

import { AebModule } from './aeb/aeb.module';
import { StoreModule } from '@ngrx/store';

@Injectable()
export class MatPaginatorIntlSpanish extends MatPaginatorIntl {
  itemsPerPageLabel = 'Elementos por página';
  nextPageLabel     = 'Página siguente';
  previousPageLabel = 'Página anterior';
  getRangeLabel: (page: number, pageSize: number, length: number) => string;

  constructor () {
    super();
    const superGetRangeLabel = this.getRangeLabel;
    this.getRangeLabel = (page, pageSize, length) => {
      return superGetRangeLabel(page, pageSize, length).replace(/of/, 'de');
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UploadManagerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    FlexLayoutModule,

    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatSnackBarModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatDialogModule,
    MatSelectModule,
    MatBottomSheetModule,
    MatBadgeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatExpansionModule,

    AebModule,

    StoreModule.forRoot({}, {})
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    UserService,
    ProfileService,
    GroupService,
    PostService,
    PostTagService,
    PostTagAssignmentService,
    PublicationService,
    ImageService,
    DownloadService,
    UploadService,
    FileService,
    PageService,
    CountryService,
    FilePermissionService,
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlSpanish
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
