import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { PostTagAssignment, PostTagAssignmentRecord } from '@aeb/models/domain/post-tag-assignment';
import { Paginable } from '@aeb/models/support/paginable';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PostTagAssignmentService {

  constructor(
    private http: HttpClient
  ) { }

  find(params?: any): Observable<Paginable<PostTagAssignmentRecord>> {
    return this.http.get<Paginable<PostTagAssignmentRecord>>(`${environment.apiUrl}post-tag-assignments`, { params });
  }

  findOne(postId: number, tagId: number): Observable<PostTagAssignmentRecord> {
    const params: { [key: string]: string } = {
      post: postId && postId.toString(),
      tag: tagId && tagId.toString(),
    };
    return this.http.get<PostTagAssignmentRecord>(`${environment.apiUrl}post-tag-assignments`, { params });
  }

  add(dto: PostTagAssignment): Observable<PostTagAssignmentRecord> {
    return this.http.post<PostTagAssignmentRecord>(`${environment.apiUrl}post-tag-assignments`, dto);
  }

  update(dto: PostTagAssignment): Observable<PostTagAssignmentRecord> {
    return this.http.put<PostTagAssignmentRecord>(`${environment.apiUrl}post-tag-assignments`, dto);
  }

  remove(postId: number, tagId: number): Observable<PostTagAssignmentRecord> {
    const params: { [key: string]: string } = {
      post: postId && postId.toString(),
      tag: tagId && tagId.toString(),
    };
    return this.http.delete<PostTagAssignmentRecord>(`${environment.apiUrl}post-tag-assignments`, { params });
  }
}
