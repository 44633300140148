import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Group, GroupRecord } from '@aeb/models/domain/group';
import { environment } from '../../environments/environment';
import { Paginable } from '@aeb/models/support/paginable';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(
    private http: HttpClient
  ) { }

  find(params?: any): Observable<Paginable<GroupRecord>> {
    return this.http.get<Paginable<GroupRecord>>(`${environment.apiUrl}groups`, { params });
  }

  findOne(id: number, params?: any): Observable<GroupRecord> {
    return this.http.get<GroupRecord>(`${environment.apiUrl}groups/${id}`, { params });
  }

  add(body?: Group): Observable<GroupRecord> {
    return this.http.post<GroupRecord>(`${environment.apiUrl}groups`, body);
  }

  update(body?: Group): Observable<GroupRecord> {
    return this.http.post<GroupRecord>(`${environment.apiUrl}groups`, body);
  }

  remove(id: number): Observable<Paginable<GroupRecord>> {
    return this.http.delete<Paginable<GroupRecord>>(`${environment.apiUrl}groups/${id}`);
  }
}
