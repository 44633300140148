import { Injectable } from '@angular/core';
import { FileRecord } from '@aeb/models/domain/file';
import { Paginable } from '@aeb/models/support/paginable';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private http: HttpClient
  ) { }

  find(params?: any): Observable<Paginable<FileRecord>> {
    return this.http.get<Paginable<FileRecord>>(`${environment.apiUrl}files`, { params });
  }

  remove(id: number): Observable<Paginable<FileRecord>> {
    return this.http.delete<Paginable<FileRecord>>(`${environment.apiUrl}files/${id}`);
  }
}
