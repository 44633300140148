import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UploadManagerComponent } from './upload-manager.component';
import { UploadService } from '../_services/upload.service';

@Component({
  selector: 'app-home',
  styles: [`
    :host {
      display: block;
      height: 100vh;
    }

    .sidenav-container {
      height: 100%;
    }

    .sidenav {
      width: 200px;
      box-shadow: 3px 0 6px rgba(0,0,0,.24);
    }

    .container {
      padding: 0 20px;
    }

    .logo-lite {
      height: 30px;
    }

    .disabled {
      pointer-events: none;
      cursor: default;
      opacity: 0.4;
    }

    @media (max-width: 767px) {
      .container {
        padding: 0 0;
      }
    }

    .mat-nav-list .mat-list-item.mat-2-line {
      height: 48px;
    }

    mat-sidenav-content {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    mat-sidenav-content > .flex-container {
      position: relative;
      flex: 1;
      overflow: auto;
    }

    .flex-container > .container {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    .is-active {
      background: #ff462e;
      color: white;
    }
    .is-active:active,
    .is-active:hover,
    .is-active:focus
     {
      background: #f21c00;
    }

    .menu-item h5 {
      margin: 0;
      font-weight: normal;
    }

    .menu-item h4 {
      font-weight: bold;
    }

    h3 {
      text-transform: uppercase;
    }
  `],
  template: `
    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav
        #drawer
        class="sidenav"
        fixedInViewport="true"
        [attr.role]="(isHandset$ | async) === true ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) === true ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false">
        <mat-toolbar color="primary">
          <img class="logo-lite" src="/assets/img/logo-lite.svg">
          <span style="font-family: serif">AEB</span>
        </mat-toolbar>
        <mat-nav-list>
          <h3 mat-subheader>Administración</h3>
          <a
            class="menu-item"
            mat-list-item
            [routerLink]="['/dashboard']"
            [routerLinkActive]="['is-active']"
            title="Dashboard">
            <mat-icon mat-list-icon>dashboard</mat-icon>
            <div mat-line><h5></h5></div>
            <h4 mat-line>Dashboard</h4>
          </a>
          <a
            class="menu-item"
            mat-list-item
            [routerLink]="['/profiles']"
            [routerLinkActive]="['is-active']"
            title="Perfiles">
            <mat-icon mat-list-icon>person</mat-icon>
            <div mat-line><h5></h5></div>
            <h4 mat-line>Perfiles</h4>
          </a>
          <a
            class="menu-item"
            mat-list-item
            [routerLink]="['/groups']"
            [routerLinkActive]="['is-active']"
            title="Grupos">
            <mat-icon mat-list-icon>people</mat-icon>
            <div mat-line><h5></h5></div>
            <h4 mat-line>Grupos</h4>
          </a>
          <a
            class="menu-item disabled"
            mat-list-item
            [routerLink]="['/users']"
            [routerLinkActive]="['is-active']"
            title="Cuentas de usuarios">
            <mat-icon mat-list-icon>account_box</mat-icon>
            <div mat-line><h5>Cuentas de</h5></div>
            <h4 mat-line>Usuarios</h4>
          </a>
          <a
            class="menu-item disabled"
            mat-list-item
            [routerLink]="['/payment-methods']"
            [routerLinkActive]="['is-active']"
            title="Formas de pago">
            <mat-icon mat-list-icon>monetization_on</mat-icon>
            <div mat-line><h5>Formas de</h5></div>
            <h4 mat-line>Pago</h4>
          </a>
          <a
            class="menu-item"
            mat-list-item
            [routerLink]="['/products']"
            [routerLinkActive]="['is-active']"
            title="Suscripciones">
            <mat-icon mat-list-icon>payment</mat-icon>
            <div mat-line><h5>Tipos de</h5></div>
            <h4 mat-line>Suscripciones</h4>
          </a>
          <a
            class="menu-item"
            mat-list-item
            [routerLink]="['/offer-packs']"
            [routerLinkActive]="['is-active']"
            title="Listas de Ofertas">
            <mat-icon mat-list-icon>list_alt</mat-icon>
            <div mat-line><h5>Listas de</h5></div>
            <h4 mat-line>Ofertas</h4>
          </a>
          <a
            class="menu-item"
            mat-list-item
            [routerLink]="['/offers']"
            [routerLinkActive]="['is-active']"
            title="Ofertas">
            <mat-icon mat-list-icon>list</mat-icon>
            <div mat-line><h5></h5></div>
            <h4 mat-line>Ofertas</h4>
          </a>
          <a
            class="menu-item"
            mat-list-item
            [routerLink]="['/orders']"
            [routerLinkActive]="['is-active']"
            title="Suscripciones">
            <mat-icon mat-list-icon>assignment</mat-icon>
            <div mat-line><h5></h5></div>
            <h4 mat-line>Pedidos</h4>
          </a>
          <mat-divider></mat-divider>
          <h3 mat-subheader>Gestión de contenido</h3>
          <a
            class="menu-item disabled"
            mat-list-item
            [routerLink]="['/navigation']"
            [routerLinkActive]="['is-active']"
            title="Navegación">
            <mat-icon mat-list-icon>explore</mat-icon>
            <div mat-line><h5></h5></div>
            <h4 mat-line>Navegación</h4>
          </a>
          <a
            class="menu-item"
            mat-list-item
            [routerLink]="['/pages']"
            [routerLinkActive]="['is-active']"
            title="Páginas">
            <mat-icon mat-list-icon>description</mat-icon>
            <div mat-line><h5></h5></div>
            <h4 mat-line>Páginas</h4>
          </a>
          <a
            class="menu-item"
            mat-list-item
            [routerLink]="['/magazines']"
            [routerLinkActive]="['is-active']"
            title="Revistas">
            <mat-icon mat-list-icon>import_contacts</mat-icon>
            <div mat-line><h5></h5></div>
            <h4 mat-line>Revistas</h4>
          </a>
          <a
            class="menu-item"
            mat-list-item
            [routerLink]="['/posts']"
            [routerLinkActive]="['is-active']"
            title="Noticias">
            <mat-icon mat-list-icon>import_contacts</mat-icon>
            <div mat-line><h5></h5></div>
            <h4 mat-line>Noticias</h4>
          </a>
          <a
            class="menu-item"
            mat-list-item
            [routerLink]="['/publications']"
            [routerLinkActive]="['is-active']"
            title="Novedades Bibliográficas">
            <mat-icon mat-list-icon>art_track</mat-icon>
            <div mat-line><h5>Novedades</h5></div>
            <h4 mat-line>Bibliográficas</h4>
          </a>
          <a
            class="menu-item"
            mat-list-item
            [routerLink]="['/hyperlink-lists']"
            [routerLinkActive]="['is-active']"
            title="Enlaces de interés">
            <mat-icon mat-list-icon>link</mat-icon>
            <div mat-line><h5>Enlaces de</h5></div>
            <h4 mat-line>Interés</h4>
          </a>
          <a
            class="menu-item"
            mat-list-item
            [routerLink]="['/events']"
            [routerLinkActive]="['is-active']"
            title="Eventos">
            <mat-icon mat-list-icon>event_seat</mat-icon>
            <div mat-line><h5></h5></div>
            <h4 mat-line>Eventos</h4>
          </a>
          <!--<a
            class="menu-item"
            mat-list-item
            [routerLink]="['/newsletters']"
            [routerLinkActive]="['is-active']"
            title="Enlaces de interés">
            <mat-icon mat-list-icon>email</mat-icon>
            <div mat-line><h5>Envío de</h5></div>
            <h4 mat-line>Boletines</h4>
          </a>-->
          <mat-divider></mat-divider>
          <h3 mat-subheader>Reportes</h3>
          <a
            class="menu-item disabled"
            mat-list-item
            [routerLink]="['/reports']"
            [routerLinkActive]="['is-active']"
            title="Reporte de Pagos">
            <mat-icon mat-list-icon>local_atm</mat-icon>
            <div mat-line><h5>Reporte de</h5></div>
            <h4 mat-line>Ventas</h4>
          </a>
          <mat-divider></mat-divider>
          <h3 mat-subheader>Recursos</h3>
          <a
            class="menu-item"
            mat-list-item
            [routerLink]="['/gallery']"
            [routerLinkActive]="['is-active']"
            title="Galería de Imágenes">
            <mat-icon mat-list-icon>image</mat-icon>
            <div mat-line><h5>Galería de</h5></div>
            <h4 mat-line>Imágenes</h4>
          </a>
          <a
            class="menu-item"
            mat-list-item
            [routerLink]="['/files']"
            [routerLinkActive]="['is-active']"
            title="Galería de Archivos">
            <mat-icon mat-list-icon>folder</mat-icon>
            <div mat-line><h5>Galería de</h5></div>
            <h4 mat-line>Archivos</h4>
          </a>
          <a
            class="menu-item disabled"
            mat-list-item
            [routerLink]="['/patterns']"
            [routerLinkActive]="['is-active']"
            title="Galería de Plantillas">
            <mat-icon mat-list-icon>web</mat-icon>
            <div mat-line><h5>Galería de</h5></div>
            <h4 mat-line>Plantillas</h4>
          </a>
          <a
            class="menu-item disabled"
            mat-list-item
            [routerLink]="['/elements']"
            [routerLinkActive]="['is-active']"
            title="Galería de Elementos">
            <mat-icon mat-list-icon>polymer</mat-icon>
            <div mat-line><h5>Galería de</h5></div>
            <h4 mat-line>Elementos</h4>
          </a>
          <mat-divider></mat-divider>
          <h3 mat-subheader>Soporte Técnico</h3>
          <a
            class="menu-item disabled"
            mat-list-item
            [routerLink]="['/docs']"
            [routerLinkActive]="['is-active']"
            title="Documentos de Ayuda">
            <mat-icon mat-list-icon>help</mat-icon>
            <div mat-line><h5>Documentos de</h5></div>
            <h4 mat-line>Ayuda</h4>
          </a>
          <a
            class="menu-item disabled"
            mat-list-item
            [routerLink]="['/contact']"
            [routerLinkActive]="['is-active']"
            title="Galería de Elementos">
            <mat-icon mat-list-icon>contact_phone</mat-icon>
            <div mat-line><h5>Contacto</h5></div>
            <h4 mat-line>Proveedor</h4>
          </a>
          <div style="margin-bottom: 20px;"></div>
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="content-header" style="position: sticky; top: 0; z-index: 1;">
          <mat-toolbar color="primary" >
            <button
                type="button"
                aria-label="Toggle sidenav"
                mat-icon-button
                (click)="drawer.toggle()"
                *ngIf="isHandset$ | async">
              <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <span style="flex: 1;"></span>
            <button
              (click)="showUploadManager()"
              [matBadge]="uploadService.pending"
              [matBadgeHidden]="uploadService.pending < 1"
              matBadgeColor="warn"
              mat-button>
              <mat-icon mat-list-icon>cloud_upload</mat-icon>
              SUBIDAS
            </button>
            <a [routerLink]="['/login']" mat-button>
              <mat-icon mat-list-icon>exit_to_app</mat-icon>
              CERRAR SESIÓN
            </a>
          </mat-toolbar>
        </div>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  `
})
export class HomeComponent {

  currentRoute: string;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    public uploadService: UploadService,
    private bottomSheet: MatBottomSheet
  ) { }

  logout() {
    this.router.navigate(['/login']);
  }

  showUploadManager () {
    this.bottomSheet.open(UploadManagerComponent, { data: this });
  }

}
