import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'profiles',
        canActivate: [AuthGuard],
        loadChildren: () => import('./profiles/profiles.module').then(m => m.ProfilesModule),
      },
      {
        path: 'posts',
        canActivate: [AuthGuard],
        loadChildren: () => import('./posts/posts.module').then(m => m.PostsModule),
      },
      {
        path: 'publications',
        canActivate: [AuthGuard],
        loadChildren: () => import('./publications/publications.module').then(m => m.PublicationsModule),
      },
      {
        path: 'gallery',
        canActivate: [AuthGuard],
        loadChildren: () => import('./images/images.module').then(m => m.ImagesModule),
      },
      {
        path: 'files',
        canActivate: [AuthGuard],
        loadChildren: () => import('./files/files.module').then(m => m.FilesModule),
      },
      {
        path: 'pages',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
      },
      {
        path: 'magazines',
        canActivate: [AuthGuard],
        loadChildren: () => import('./magazines/magazines.module').then(m => m.MagazinesModule),
      },
      {
        path: 'groups',
        canActivate: [AuthGuard],
        loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule),
      },
      {
        path: 'products',
        canActivate: [AuthGuard],
        loadChildren: () => import('./products/products.module').then(m => m.ProductsModule),
      },
      {
        path: 'offers',
        canActivate: [AuthGuard],
        loadChildren: () => import('./offers/offers.module').then(m => m.OffersModule),
      },
      {
        path: 'offer-packs',
        canActivate: [AuthGuard],
        loadChildren: () => import('./offer-packs/offer-packs.module').then(m => m.OfferPacksModule),
      },
      {
        path: 'orders',
        canActivate: [AuthGuard],
        loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
      },
      {
        path: 'hyperlink-lists',
        canActivate: [AuthGuard],
        loadChildren: () => import('./hyperlink-lists/hyperlink-lists.module').then(m => m.HyperlinkListsModule),
      },
      {
        path: 'newsletters',
        canActivate: [AuthGuard],
        loadChildren: () => import('./newsletters/newsletters.module').then(m => m.NewslettersModule),
      },
      {
        path: 'events',
        canActivate: [AuthGuard],
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      }
    ]
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
