import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FilePermission, FilePermissionRecord } from '@aeb/models/domain/file-permission';
import { environment } from '../../environments/environment';
import { Paginable } from '@aeb/models/support/paginable';

@Injectable({
  providedIn: 'root'
})
export class FilePermissionService {

  constructor(
    private http: HttpClient
  ) { }

  find(params?: any): Observable<Paginable<FilePermissionRecord>> {
    return this.http.get<Paginable<FilePermissionRecord>>(`${environment.apiUrl}file-permissions`, { params });
  }

  findOne(id: number, params?: any): Observable<FilePermissionRecord> {
    return this.http.get<FilePermissionRecord>(`${environment.apiUrl}file-permissions/${id}`, { params });
  }

  add(body?: FilePermission): Observable<FilePermissionRecord> {
    return this.http.post<FilePermissionRecord>(`${environment.apiUrl}file-permissions`, body);
  }

  update(body?: FilePermission): Observable<FilePermissionRecord> {
    return this.http.post<FilePermissionRecord>(`${environment.apiUrl}file-permissions`, body);
  }

  remove(id: number): Observable<Paginable<FilePermissionRecord>> {
    return this.http.delete<Paginable<FilePermissionRecord>>(`${environment.apiUrl}file-permissions/${id}`);
  }
}
