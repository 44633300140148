import { Injectable } from '@angular/core';
import { FileRecord } from '@aeb/models/domain/file';
import { Paginable } from '@aeb/models/support/paginable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(
    private http: HttpClient
  ) { }

  download(file: FileRecord) {
    this.http.get(file.downloadUrl, { responseType: 'arraybuffer' }).subscribe(this.downloadFile(file), error => console.log(error));
  }

  downloadFile(file: FileRecord) {
    return (data: ArrayBuffer) => {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const blob = new Blob([data], { type: file.mimetype });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = file.originalname;
      a.click();
      //window.URL.revokeObjectURL(url);
      //setTimeout(() => a.remove(), 10);
    };
  }
}
