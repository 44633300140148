import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { PostTagRecord } from '@aeb/models/domain/post-tag';
import { Paginable } from '@aeb/models/support/paginable';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PostTagService {

  constructor(
    private http: HttpClient
  ) { }

  find(params?: any): Observable<Paginable<PostTagRecord>> {
    return this.http.get<Paginable<PostTagRecord>>(`${environment.apiUrl}post-tags`, { params });
  }

  findOne(id: number, params?: any): Observable<PostTagRecord> {
    return this.http.get<PostTagRecord>(`${environment.apiUrl}post-tags/${id}`, { params });
  }
}
