import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Publication, PublicationRecord } from '@aeb/models/domain/publication';
import { environment } from '../../environments/environment';
import { Paginable } from '@aeb/models/support/paginable';

@Injectable({
  providedIn: 'root'
})
export class PublicationService {

  constructor(
    private http: HttpClient
  ) { }

  find(params?: any): Observable<Paginable<PublicationRecord>> {
    return this.http.get<Paginable<PublicationRecord>>(`${environment.apiUrl}publications`, { params });
  }

  findOne(id: number, params?: any): Observable<PublicationRecord> {
    return this.http.get<PublicationRecord>(`${environment.apiUrl}publications/${id}`, { params });
  }

  add(body?: Publication): Observable<PublicationRecord> {
    return this.http.post<PublicationRecord>(`${environment.apiUrl}publications`, body);
  }

  update(body?: Publication): Observable<PublicationRecord> {
    return this.http.post<PublicationRecord>(`${environment.apiUrl}publications`, body);
  }

  remove(id: number): Observable<Paginable<PublicationRecord>> {
    return this.http.delete<Paginable<PublicationRecord>>(`${environment.apiUrl}publications/${id}`);
  }
}
