import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { FileUploader } from 'ng2-file-upload';
import { UploadService } from '../_services/upload.service';

@Component({
  styles: [`
    .mat-button {
      min-width: 40px;
      padding: 0;
    }
  `],
  template: `
    <h5 style="text-transform: uppercase;">Subidas</h5>
    <mat-card *ngFor="let upload of uploadService.uploads">
      <small style="padding-bottom: 5px;">{{upload.file.name}}</small>
      <mat-progress-bar mode="determinate" [value]="upload.uploader.progress"></mat-progress-bar>
      <div style="float: right;">
        <small *ngIf="upload.uploader.progress === 100">Completado</small>
        <small *ngIf="upload.uploader.progress < 100">En progreso {{upload.file.size | bytes}} bytes</small>
      </div>
      <div style="clear: both;"></div>
      <button *ngIf="upload.uploader.progress === 100" mat-button style="position: absolute; top: 0; right: 0;" (click)="removeUpload(upload)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-card>
    <mat-nav-list>
      <mat-divider></mat-divider>
      <mat-list-item (click)="close()">
        <mat-icon matSuffix>arrow_downward</mat-icon>
        <div matLine>Cerrar</div>
      </mat-list-item>
    </mat-nav-list>
    <!-- 1h 10m restantes - 700 KB de 100 MB (22,3 KB/seg.) -->
  `
})
export class UploadManagerComponent {

  public uploaders: { uploader: FileUploader[], file: File, origin: string }[] = [];

  constructor(
    public uploadService: UploadService,
    private bottomSheetRef: MatBottomSheetRef<UploadManagerComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) { }

  close() {
    this.bottomSheetRef.dismiss();
  }

  removeUpload(upload: { uploader: FileUploader, file: File, origin: string }) {
    this.uploadService.remove(upload);
  }
}
