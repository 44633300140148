import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpClient
} from '@angular/common/http';
import { AuthenticationService } from './_services/authentication.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { mergeMap } from "rxjs/operators";
import { environment } from '../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public http: HttpClient,
    public auth: AuthenticationService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'Authorization': `Bearer ${this.auth.accessToken}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    return next.handle(request)
      .pipe(catchError(this.handleAuthError(request, next)));
  }

  private handleAuthError(request: HttpRequest<any>, next: HttpHandler) {

    return (error: HttpErrorResponse): Observable<any> => {
      if ((error.status === 401 || error.status === 403)) {
        if (request.url.startsWith(`${environment.apiUrl}downloads/`)) {
          return throwError(error);
        }
        if (request.url.startsWith(`${environment.apiUrl}auth/`)) {
          this.auth.logout();
          return throwError(error);
        }
        if (this.auth.refreshToken) {
          return this.auth.refresh()
            .pipe<any>(mergeMap((response) => {
              if (response) {
                return this.http.request(request.clone());
              }
              this.router.navigate(['/login']);
              return throwError(error);
            }));
        }
        this.router.navigate(['/login']);
      }
      return throwError(error);
    };
  }
}