import { Injectable, EventEmitter } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { AuthenticationService } from '../_services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class UploadService extends EventEmitter<string> {

  public maxUploads: number = 5;
  private _uploads: { uploader: FileUploader, file: File, origin: string }[] = [];
  public pending: number = 0;

  get uploads () {
    return this._uploads;
  }

  constructor (
    public authenticationService: AuthenticationService,
    public snackBar: MatSnackBar,
  ) {
    super();
  }

  add (upload: { uploader: FileUploader, file: File, origin: string, response?: Object }) {
    this._uploads.push(upload);
    this.updatePending();
    upload.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if (status >= 200 && status < 300) {
        this.openSnackBar(`Archivo "${upload.file.name}" fue subido exitosamente`, 'AVISO');
        this.emit(upload.origin);
      } else {
        this.openSnackBar(`Ha ocurrido un error al subir el archivo "${upload.file.name}"`, 'ERROR');
      }
      this.updatePending();
    };
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  remove (upload: { uploader: FileUploader, file: File, origin: string, response?: Object }) {
    this._uploads = this._uploads.filter(_upload => _upload !== upload);
  }

  updatePending() {
    this.pending = this._uploads.filter(upload => upload.uploader.progress < 100).length;
  }
}
