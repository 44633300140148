import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from '../../environments/environment';

interface TokenData {
  accessToken: string;
  refreshToken: string;
  user?: any;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public accessToken: string;
  public refreshToken: string;

  constructor(private http: HttpClient) {
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.accessToken = currentUser && currentUser.accessToken;
    this.refreshToken = currentUser && currentUser.refreshToken;
  }

  login (email: string, password: string): Observable<boolean> {
    const body = JSON.stringify({ email, password });

    return this.http.post<TokenData>(`${environment.apiUrl}auth/login`, body)
      .pipe<boolean>(map((tokenData) => {
        const { accessToken, refreshToken } = tokenData;
        if (accessToken) {
          this.accessToken = accessToken;
          this.refreshToken = refreshToken;
          localStorage.setItem('currentUser', JSON.stringify({ email, accessToken, refreshToken }));
          return true;
        } else {
          return false;
        }
      }));
  }

  refresh (): Observable<boolean> {
    if (this.refreshToken) {
      return this.http.post<TokenData>(`${environment.apiUrl}auth/refresh`, { refresh_token: this.refreshToken })
        .pipe<boolean>(map((tokenData) => {
          const { accessToken, refreshToken } = tokenData;
          if (accessToken) {
            this.accessToken = accessToken;
            this.refreshToken = refreshToken;
            const email = JSON.parse(localStorage.getItem('currentUser')).email;
            localStorage.setItem('currentUser', JSON.stringify({ email, accessToken, refreshToken }));
            return true;
          } else {
            return false;
          }
        }));
    }

    return of(false);
  }

  logout (): void {
    this.accessToken = null;
    this.refreshToken = null;
    localStorage.removeItem('currentUser');
  }

  verify (): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrl}auth/verify`, {
      headers: {
        'Authorization': `Bearer ${this.accessToken}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
  }
}
